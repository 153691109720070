<template>
  <div class='row'>
    <div class='col-lg-6 col-12'>
      <div class='card card-top card-top-primary'>
        <div class='card-header'>
          <vb-headers-card-header :data="{ title: 'Yeni Slider Ekle' }" />
        </div>

        <div class='card-body'>

          <image-resizer
            :loading='loading'
            :on-complete='beforeUpload'
            :max-size='1280'
            required
            :error-text='"Fotoğraf 1280x470 piksel ölçülerinde olmalıdır. (*)"'
          />
          <a-form
            label-align='left'
            layout='vertical'
          >
            <div class='row mt-2'>
              <div class='col-12 col-sm'>
                <a-form-item label='Link' name='link'>
                  <a-input v-model:value='link' placeholder='Format: "/antrenmanlarim" ya da "https://onlinetraining.com.tr"' />
                  <a-tag color='warning' class='mt-2'>
                    <template #icon>
                      <i class='fe fe-alert-circle' />
                    </template>
                    Dikkat: "http" ile başlayan link verildiğinde uygulama dışına yönlendirir. <br/>
                    Uygulama içi link kullanmak istiyorsanız başına slash(/) yazarak belirtiniz. Örnek(/antrenmanlarım) <br/>
                    Yönlendirme istemiyorsanız boş bırakınız.
                  </a-tag>
                </a-form-item>

                <a-form-item label='Sıralama (*)' name='order'>
                  <a-input v-model:value='order' v-maska='"###"' placeholder='Sayı Belirtiniz' />
                </a-form-item>
              </div>
            </div>
          </a-form>

          <a-button key='submit' class='btn btn-success px-5' :loading='loading' @click='handleNewCarouselImage'
                    :disabled='!validateNewPTForm'>
            Kaydet
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { roundHalf } from '@/utils/number'
import { notification } from 'ant-design-vue'
import { CarouselAdministration } from '../../services/api-service'

import { useRouter } from 'vue-router'

export default {
  name: 'YeniSliderEkle',
  data() {
    const router = useRouter()

    return {
      router,
      loading: false,
      roundHalf,
      dateFormat: 'YYYY/MM/DD',
      monthFormat: 'YYYY/MM',

      newImage: '',
      link: '',
      order: '0',
    }
  },
  computed: {
    validateNewPTForm() {
      return (
        this.order !== '' &&
        this.newImage !== ''
      )
    },
  },
  methods: {
    beforeUpload(file) {
      this.newImage = file
    },
    async handleNewCarouselImage() {
      if (this.loading) {
        return
      }
      this.loading = true
      try {
        await CarouselAdministration.createCarouselImageForAdministration({
          newImage: this.newImage,
          link: this.link,
          order: this.order,
        })

        notification.success({
          message: 'Slider Başarıyla Eklendi!',
        })

        await this.router.push({ path: '/slider-yonetimi' })

      } catch {
        this.loading = false
      }
    },
  },
}
</script>
